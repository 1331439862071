a.text-primary:focus, a.text-primary:hover {
  color: #7b7b7b;
}

a.text-success:focus, a.text-success:hover {
  color: #022501;
}

a.text-info:focus, a.text-info:hover {
  color: #215f79;
}

a.text-warning:focus, a.text-warning:hover {
  color: #251f01;
}

a.text-danger:focus, a.text-danger:hover {
  color: #7f1212;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #7b7b7b;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #95e294;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #98d6f1;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ffe671;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ff9696;
}

.table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > thead > tr > td.active, .table > thead > tr > th.active {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8;
}

.table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > thead > tr > td.success, .table > thead > tr > th.success {
  background-color: #bdedbc;
}

.table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr.success:hover > th, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover {
  background-color: #a9e8a8;
}

.table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > thead > tr > td.info, .table > thead > tr > th.info {
  background-color: #c5e8f7;
}

.table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr.info:hover > th, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover {
  background-color: #afdff4;
}

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > thead > tr > td.warning, .table > thead > tr > th.warning {
  background-color: #ffefa4;
}

.table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr.warning:hover > th, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover {
  background-color: #ffeb8a;
}

.table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > thead > tr > td.danger, .table > thead > tr > th.danger {
  background-color: #ffc9c9;
}

.table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr.danger:hover > th, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover {
  background-color: #ffafaf;
}

.form-control:focus {
  border-color: #cbd0d9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(203, 208, 217, 0.6);
}

.form-control:-moz-placeholder {
  color: #aaa;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

select.input-sm {
  height: 28px;
  line-height: 28px;
}

select[multiple].input-sm, textarea.input-sm {
  height: auto;
}

select.input-lg {
  height: 42px;
  line-height: 42px;
}

select[multiple].input-lg, textarea.input-lg {
  height: auto;
}

.has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .help-block, .has-success .radio, .has-success .radio-inline, .has-success.checkbox label, .has-success.checkbox-inline label, .has-success.radio label, .has-success.radio-inline label {
  color: #045702;
}

.has-success .form-control {
  border-color: #045702;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #022501;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #09bb04;
}

.has-success .input-group-addon {
  color: #045702;
  border-color: #045702;
  background-color: #bdedbc;
}

.has-success .form-control-feedback {
  color: #045702;
}

.has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline, .has-warning.checkbox label, .has-warning.checkbox-inline label, .has-warning.radio label, .has-warning.radio-inline label {
  color: #574802;
}

.has-warning .form-control {
  border-color: #574802;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #251f01;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #bb9b04;
}

.has-warning .input-group-addon {
  color: #574802;
  border-color: #574802;
  background-color: #ffefa4;
}

.has-warning .form-control-feedback {
  color: #574802;
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
  color: #ac1818;
}

.has-error .form-control {
  border-color: #ac1818;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #7f1212;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e54545;
}

.has-error .input-group-addon {
  color: #ac1818;
  border-color: #ac1818;
  background-color: #ffc9c9;
}

.has-error .form-control-feedback {
  color: #ac1818;
}

.btn {
  outline: 0 !important;
}

.btn:active {
  box-shadow: none;
}

.btn.btn-icon {
  position: relative;
}

.btn.btn-icon i {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.btn-default {
  color: #303641;
  background-color: #f0f0f1;
  border-color: #f0f0f1;
}

.btn-default.focus, .btn-default:focus {
  color: #303641;
  background-color: #d6d6d8;
  border-color: #aeaeb3;
}

.btn-default.active, .btn-default:active, .btn-default:hover, .open > .dropdown-toggle.btn-default {
  color: #303641;
  background-color: #d6d6d8;
  border-color: #d0d0d3;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: #303641;
  background-color: #c3c3c7;
  border-color: #aeaeb3;
}

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #f0f0f1;
  border-color: #f0f0f1;
}

.btn-default .badge {
  color: #f0f0f1;
  background-color: #303641;
}

.btn-default.focus, .btn-default:focus, .btn-default:hover {
  color: #303641 !important;
}

.btn-default.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-default.btn-icon i {
  background-color: #dbdbdd;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-default.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-default.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-default.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-default.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-default.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-default.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-default.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-default.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-default.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-default.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-default.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-primary {
  color: #fff;
  background-color: #303641;
  border-color: #252a32;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #1a1e24;
  border-color: #000;
}

.btn-primary.active, .btn-primary:active, .btn-primary:hover, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #1a1e24;
  border-color: #0b0d0f;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #0b0d0f;
  border-color: #000;
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #303641;
  border-color: #252a32;
}

.btn-primary .badge {
  color: #303641;
  background-color: #fff;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
  color: #fff !important;
}

.btn-primary.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-primary.btn-icon i {
  background-color: #1f232a;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-primary.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-primary.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-primary.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-primary.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-primary.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-primary.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-primary.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-primary.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-primary.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-primary.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-primary.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-blue {
  color: #fff;
  background-color: #0072bc;
  border-color: #0072bc;
}

.btn-blue.focus, .btn-blue:focus {
  color: #fff;
  background-color: #005389;
  border-color: #00253d;
}

.btn-blue.active, .btn-blue:active, .btn-blue:hover, .open > .dropdown-toggle.btn-blue {
  color: #fff;
  background-color: #005389;
  border-color: #004d7f;
}

.btn-blue.active.focus, .btn-blue.active:focus, .btn-blue.active:hover, .btn-blue:active.focus, .btn-blue:active:focus, .btn-blue:active:hover, .open > .dropdown-toggle.btn-blue.focus, .open > .dropdown-toggle.btn-blue:focus, .open > .dropdown-toggle.btn-blue:hover {
  color: #fff;
  background-color: #003d65;
  border-color: #00253d;
}

.btn-blue.active, .btn-blue:active, .open > .dropdown-toggle.btn-blue {
  background-image: none;
}

.btn-blue.disabled.focus, .btn-blue.disabled:focus, .btn-blue.disabled:hover, .btn-blue[disabled].focus, .btn-blue[disabled]:focus, .btn-blue[disabled]:hover, fieldset[disabled] .btn-blue.focus, fieldset[disabled] .btn-blue:focus, fieldset[disabled] .btn-blue:hover {
  background-color: #0072bc;
  border-color: #0072bc;
}

.btn-blue .badge {
  color: #0072bc;
  background-color: #fff;
}

.btn-blue.focus, .btn-blue:focus, .btn-blue:hover {
  color: #fff !important;
}

.btn-blue.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-blue.btn-icon i {
  background-color: #005993;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-blue.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-blue.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-blue.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-blue.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-blue.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-blue.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-blue.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-blue.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-blue.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-blue.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-blue.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-red {
  color: #fff;
  background-color: #d42020;
  border-color: #d42020;
}

.btn-red.focus, .btn-red:focus {
  color: #fff;
  background-color: #a81919;
  border-color: #650f0f;
}

.btn-red.active, .btn-red:active, .btn-red:hover, .open > .dropdown-toggle.btn-red {
  color: #fff;
  background-color: #a81919;
  border-color: #9f1818;
}

.btn-red.active.focus, .btn-red.active:focus, .btn-red.active:hover, .btn-red:active.focus, .btn-red:active:focus, .btn-red:active:hover, .open > .dropdown-toggle.btn-red.focus, .open > .dropdown-toggle.btn-red:focus, .open > .dropdown-toggle.btn-red:hover {
  color: #fff;
  background-color: #891515;
  border-color: #650f0f;
}

.btn-red.active, .btn-red:active, .open > .dropdown-toggle.btn-red {
  background-image: none;
}

.btn-red.disabled.focus, .btn-red.disabled:focus, .btn-red.disabled:hover, .btn-red[disabled].focus, .btn-red[disabled]:focus, .btn-red[disabled]:hover, fieldset[disabled] .btn-red.focus, fieldset[disabled] .btn-red:focus, fieldset[disabled] .btn-red:hover {
  background-color: #d42020;
  border-color: #d42020;
}

.btn-red .badge {
  color: #d42020;
  background-color: #fff;
}

.btn-red.focus, .btn-red:focus, .btn-red:hover {
  color: #fff !important;
}

.btn-red.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-red.btn-icon i {
  background-color: #b11b1b;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-red.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-red.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-red.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-red.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-red.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-red.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-red.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-red.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-red.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-red.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-red.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-orange {
  color: #fff;
  background-color: #ff9600;
  border-color: #ff9600;
}

.btn-orange.focus, .btn-orange:focus {
  color: #fff;
  background-color: #cc7800;
  border-color: #804b00;
}

.btn-orange.active, .btn-orange:active, .btn-orange:hover, .open > .dropdown-toggle.btn-orange {
  color: #fff;
  background-color: #cc7800;
  border-color: #c27200;
}

.btn-orange.active.focus, .btn-orange.active:focus, .btn-orange.active:hover, .btn-orange:active.focus, .btn-orange:active:focus, .btn-orange:active:hover, .open > .dropdown-toggle.btn-orange.focus, .open > .dropdown-toggle.btn-orange:focus, .open > .dropdown-toggle.btn-orange:hover {
  color: #fff;
  background-color: #a86300;
  border-color: #804b00;
}

.btn-orange.active, .btn-orange:active, .open > .dropdown-toggle.btn-orange {
  background-image: none;
}

.btn-orange.disabled.focus, .btn-orange.disabled:focus, .btn-orange.disabled:hover, .btn-orange[disabled].focus, .btn-orange[disabled]:focus, .btn-orange[disabled]:hover, fieldset[disabled] .btn-orange.focus, fieldset[disabled] .btn-orange:focus, fieldset[disabled] .btn-orange:hover {
  background-color: #ff9600;
  border-color: #ff9600;
}

.btn-orange .badge {
  color: #ff9600;
  background-color: #fff;
}

.btn-orange.focus, .btn-orange:focus, .btn-orange:hover {
  color: #fff !important;
}

.btn-orange.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-orange.btn-icon i {
  background-color: #d67e00;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-orange.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-orange.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-orange.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-orange.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-orange.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-orange.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-orange.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-orange.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-orange.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-orange.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-orange.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-gold {
  color: #846e20;
  background-color: #fcd036;
  border-color: #fcd036;
}

.btn-gold.focus, .btn-gold:focus {
  color: #846e20;
  background-color: #fbc404;
  border-color: #b08903;
}

.btn-gold.active, .btn-gold:active, .btn-gold:hover, .open > .dropdown-toggle.btn-gold {
  color: #846e20;
  background-color: #fbc404;
  border-color: #f1bc04;
}

.btn-gold.active.focus, .btn-gold.active:focus, .btn-gold.active:hover, .btn-gold:active.focus, .btn-gold:active:focus, .btn-gold:active:hover, .open > .dropdown-toggle.btn-gold.focus, .open > .dropdown-toggle.btn-gold:focus, .open > .dropdown-toggle.btn-gold:hover {
  color: #846e20;
  background-color: #d8a903;
  border-color: #b08903;
}

.btn-gold.active, .btn-gold:active, .open > .dropdown-toggle.btn-gold {
  background-image: none;
}

.btn-gold.disabled.focus, .btn-gold.disabled:focus, .btn-gold.disabled:hover, .btn-gold[disabled].focus, .btn-gold[disabled]:focus, .btn-gold[disabled]:hover, fieldset[disabled] .btn-gold.focus, fieldset[disabled] .btn-gold:focus, fieldset[disabled] .btn-gold:hover {
  background-color: #fcd036;
  border-color: #fcd036;
}

.btn-gold .badge {
  color: #fcd036;
  background-color: #846e20;
}

.btn-gold.focus, .btn-gold:focus, .btn-gold:hover {
  color: #846e20 !important;
}

.btn-gold.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-gold.btn-icon i {
  background-color: #fbc70e;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-gold.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-gold.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-gold.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-gold.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-gold.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-gold.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-gold.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-gold.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-gold.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-gold.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-gold.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-black, .btn-black.active, .btn-black.active.focus, .btn-black.active:focus, .btn-black.active:hover, .btn-black.focus, .btn-black:active, .btn-black:active.focus, .btn-black:active:focus, .btn-black:active:hover, .btn-black:focus, .btn-black:hover, .open > .dropdown-toggle.btn-black, .open > .dropdown-toggle.btn-black.focus, .open > .dropdown-toggle.btn-black:focus, .open > .dropdown-toggle.btn-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black.active, .btn-black:active, .open > .dropdown-toggle.btn-black {
  background-image: none;
}

.btn-black.disabled.focus, .btn-black.disabled:focus, .btn-black.disabled:hover, .btn-black[disabled].focus, .btn-black[disabled]:focus, .btn-black[disabled]:hover, fieldset[disabled] .btn-black.focus, fieldset[disabled] .btn-black:focus, fieldset[disabled] .btn-black:hover {
  background-color: #000;
  border-color: #000;
}

.btn-black .badge {
  color: #000;
  background-color: #fff;
}

.btn-black.focus, .btn-black:focus, .btn-black:hover {
  color: #fff !important;
}

.btn-black.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-black.btn-icon i {
  background-color: #000;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-black.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-black.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-black.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-black.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-black.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-black.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-black.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-black.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-black.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-black.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-black.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-white {
  color: #303641;
  background-color: #fff;
  border-color: #ebebeb !important;
}

.btn-white.focus, .btn-white:focus {
  color: #303641;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}

.btn-white.active, .btn-white:active, .btn-white:hover, .open > .dropdown-toggle.btn-white {
  color: #303641;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn-white.active.focus, .btn-white.active:focus, .btn-white.active:hover, .btn-white:active.focus, .btn-white:active:focus, .btn-white:active:hover, .open > .dropdown-toggle.btn-white.focus, .open > .dropdown-toggle.btn-white:focus, .open > .dropdown-toggle.btn-white:hover {
  color: #303641;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}

.btn-white.active, .btn-white:active, .open > .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.disabled.focus, .btn-white.disabled:focus, .btn-white.disabled:hover, .btn-white[disabled].focus, .btn-white[disabled]:focus, .btn-white[disabled]:hover, fieldset[disabled] .btn-white.focus, fieldset[disabled] .btn-white:focus, fieldset[disabled] .btn-white:hover {
  background-color: #fff;
  border-color: #fff;
}

.btn-white .badge {
  color: #fff;
  background-color: #303641;
}

.btn-white.focus, .btn-white:focus, .btn-white:hover {
  color: #303641 !important;
}

.btn-white.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-white.btn-icon i {
  background-color: #ebebeb;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-white.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-white.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-white.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-white.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-white.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-white.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-white.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-white.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-white.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-white.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-white.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-warning {
  color: #fff;
  background-color: #fad839;
  border-color: #f9d320;
}

.btn-warning.focus, .btn-warning:focus {
  color: #fff;
  background-color: #f9ce07;
  border-color: #967c04;
}

.btn-warning.active, .btn-warning:active, .btn-warning:hover, .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #f9ce07;
  border-color: #d7b205;
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d7b205;
  border-color: #967c04;
}

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled.focus, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning[disabled].focus, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
  background-color: #fad839;
  border-color: #f9d320;
}

.btn-warning .badge {
  color: #fad839;
  background-color: #fff;
}

.btn-warning.focus, .btn-warning:focus, .btn-warning:hover {
  color: #fff !important;
}

.btn-warning.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-warning.btn-icon i {
  background-color: #f9d011;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-warning.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-warning.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-warning.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-warning.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-warning.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-warning.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-warning.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-warning.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-warning.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-warning.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-warning.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-danger {
  color: #fff;
  background-color: #cc2424;
  border-color: #b62020;
}

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #a11c1c;
  border-color: #4a0d0d;
}

.btn-danger.active, .btn-danger:active, .btn-danger:hover, .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #a11c1c;
  border-color: #821717;
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #821717;
  border-color: #4a0d0d;
}

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled.focus, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger[disabled].focus, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
  background-color: #cc2424;
  border-color: #b62020;
}

.btn-danger .badge {
  color: #cc2424;
  background-color: #fff;
}

.btn-danger.focus, .btn-danger:focus, .btn-danger:hover {
  color: #fff !important;
}

.btn-danger.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-danger.btn-icon i {
  background-color: #a91e1e;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-danger.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-danger.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-danger.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-danger.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-danger.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-danger.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-danger.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-danger.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-danger.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-danger.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-danger.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-green, .btn-success {
  color: #fff;
  background-color: #00a651;
  border-color: #008d45;
}

.btn-green.focus, .btn-green:focus, .btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #007338;
  border-color: #000d06;
}

.btn-green.active, .btn-green:active, .btn-green:hover, .btn-success.active, .btn-success:active, .btn-success:hover, .open > .dropdown-toggle.btn-green, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #007338;
  border-color: #004f27;
}

.btn-green.active.focus, .btn-green.active:focus, .btn-green.active:hover, .btn-green:active.focus, .btn-green:active:focus, .btn-green:active:hover, .btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-green.focus, .open > .dropdown-toggle.btn-green:focus, .open > .dropdown-toggle.btn-green:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #004f27;
  border-color: #000d06;
}

.btn-green.active, .btn-green:active, .btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-green, .open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-green.disabled.focus, .btn-green.disabled:focus, .btn-green.disabled:hover, .btn-green[disabled].focus, .btn-green[disabled]:focus, .btn-green[disabled]:hover, .btn-success.disabled.focus, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled].focus, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-green.focus, fieldset[disabled] .btn-green:focus, fieldset[disabled] .btn-green:hover, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
  background-color: #00a651;
  border-color: #008d45;
}

.btn-green .badge, .btn-success .badge {
  color: #00a651;
  background-color: #fff;
}

.btn-green.focus, .btn-green:focus, .btn-green:hover, .btn-success.focus, .btn-success:focus, .btn-success:hover {
  color: #fff !important;
}

.btn-green.btn-icon, .btn-success.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-green.btn-icon i, .btn-success.btn-icon i {
  background-color: #007d3d;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-green.btn-icon.icon-left, .btn-success.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-green.btn-icon.icon-left i, .btn-success.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-green.btn-icon.btn-lg, .btn-success.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-green.btn-icon.btn-lg.icon-left, .btn-success.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-green.btn-icon.btn-lg i, .btn-success.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-green.btn-icon.btn-sm, .btn-success.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-green.btn-icon.btn-sm.icon-left, .btn-success.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-green.btn-icon.btn-sm i, .btn-success.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-green.btn-icon.btn-xs, .btn-success.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-green.btn-icon.btn-xs.icon-left, .btn-success.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-green.btn-icon.btn-xs i, .btn-success.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-info {
  color: #fff;
  background-color: #21a9e1;
  border-color: #1c99cd;
}

.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #1988b6;
  border-color: #0c455d;
}

.btn-info.active, .btn-info:active, .btn-info:hover, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #1988b6;
  border-color: #147197;
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #147197;
  border-color: #0c455d;
}

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled.focus, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info[disabled].focus, .btn-info[disabled]:focus, .btn-info[disabled]:hover, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
  background-color: #21a9e1;
  border-color: #1c99cd;
}

.btn-info .badge {
  color: #21a9e1;
  background-color: #fff;
}

.btn-info.focus, .btn-info:focus, .btn-info:hover {
  color: #fff !important;
}

.btn-info.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.btn-info.btn-icon i {
  background-color: #1a8fbf;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.btn-info.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.btn-info.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.btn-info.btn-icon.btn-lg {
  padding-right: 55px;
}

.btn-info.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.btn-info.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.btn-info.btn-icon.btn-sm {
  padding-right: 36px;
}

.btn-info.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.btn-info.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-info.btn-icon.btn-xs {
  padding-right: 32px;
}

.btn-info.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.btn-info.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

.bs-example > .btn, .bs-example > .btn-group, .bs-example > .make-switch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 9px;
}

.bs-example.bs-baseline-top .btn, .bs-example.bs-baseline-top .btn-group {
  vertical-align: top !important;
}

.invoice {
  margin: 0;
  font-size: 14px;
}

.invoice .invoice-left strong, .invoice .invoice-right strong {
  color: #303641;
}

.invoice .invoice-left > h3, .invoice .invoice-right > h3 {
  margin-top: 0;
}

.invoice .invoice-right {
  text-align: right;
}

.invoice .margin {
  margin: 40px 0;
}

.invoice h4 {
  font-weight: 700;
}

@media print {
  .invoice-left {
    float: left !important;
  }

  .invoice-right {
    float: right !important;
    text-align: right !important;
    top: -20px;
    position: relative;
  }
}

@media (max-width: 768px) {
  .invoice .invoice-right {
    text-align: left;
    margin-top: 20px;
  }

  .invoice .margin {
    margin: 15px 0;
  }

  .invoice table {
    margin: 20px 0;
  }
}

.mail-env {
  position: relative;
}

.mail-env:after, .mail-env:before {
  content: " ";
  display: table;
}

.mail-env:after {
  clear: both;
}

hr + .mail-env {
  margin-top: -18px;
  border-top: 1px solid #ebebeb;
  margin-left: -20px;
  margin-right: -20px;
}

.mail-env + hr {
  margin-top: 0;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.mail-env .mail-body, .mail-env .mail-sidebar {
  float: left;
  box-sizing: border-box;
}

.mail-env .mail-body:after, .mail-env .mail-body:before, .mail-env .mail-sidebar:after, .mail-env .mail-sidebar:before {
  content: " ";
  display: table;
}

.mail-env .mail-body:after, .mail-env .mail-sidebar:after {
  clear: both;
}

.mail-env .mail-sidebar-row {
  padding: 20px;
}

.mail-env > .mail-sidebar-row.visible-xs {
  padding-bottom: 0;
}

.mail-env .mail-sidebar {
  width: 22%;
  background: #f9f9f9;
  border-right: 1px solid #ebebeb;
  position: relative;
}

.mail-env .mail-sidebar > h4 {
  padding: 20px;
}

.mail-env .mail-sidebar .mail-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #ebebeb;
}

.mail-env .mail-sidebar .mail-menu > li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ebebeb;
}

.mail-env .mail-sidebar .mail-menu > li a {
  display: block;
  padding: 20px;
  transition: all 300ms ease-in-out;
}

.mail-env .mail-sidebar .mail-menu > li a .badge {
  font-size: 13px;
}

.mail-env .mail-sidebar .mail-menu > li a .badge-gray {
  background: 0 0;
  border: 1px solid #ebebeb;
}

.mail-env .mail-sidebar .mail-menu > li a .badge-roundless {
  display: inline-block;
  width: 14px;
  height: 14px;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
}

.mail-env .mail-sidebar .mail-menu > li:hover a {
  background: rgba(255, 255, 255, 0.8);
}

.mail-env .mail-sidebar .mail-menu > li.active a {
  background: #fff;
  font-weight: 700;
}

.mail-env .mail-sidebar .mail-distancer {
  height: 40px;
}

.mail-env .mail-body {
  width: 78%;
  float: right;
  overflow: hidden;
}

.mail-env .mail-body .mail-header {
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
}

.mail-env .mail-body .mail-header:after, .mail-env .mail-body .mail-header:before {
  content: " ";
  display: table;
}

.mail-env .mail-body .mail-header:after {
  clear: both;
}

.mail-env .mail-body .mail-header .mail-title {
  margin: 0;
  padding: 0 20px 0 0;
  font-size: 20px;
  float: left;
  width: 75%;
  color: #303641;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail-env .mail-body .mail-header .mail-title span.count {
  font-weight: 400;
  zoom: 1;
  -webkit-opacity: .3;
  -moz-opacity: .3;
  opacity: .3;
  filter: alpha(opacity=30);
}

.mail-env .mail-body .mail-header .mail-title .label {
  font-size: 9px;
  position: relative;
  top: -4px;
}

.mail-env .mail-body .mail-header .mail-links, .mail-env .mail-body .mail-header .mail-search {
  float: left;
  text-align: right;
  width: 25%;
}

.mail-env .mail-body .mail-header .mail-links.mail-links > .btn, .mail-env .mail-body .mail-header .mail-search.mail-links > .btn {
  margin-left: 5px;
  font-size: 11px;
}

.mail-env .mail-body .mail-header .mail-links.mail-links > .btn:first-child, .mail-env .mail-body .mail-header .mail-search.mail-links > .btn:first-child {
  margin-left: 0;
}

.mail-env .mail-body .mail-header .mail-links .form-control[type=text], .mail-env .mail-body .mail-header .mail-search .form-control[type=text] {
  height: 29px;
}

.mail-env .mail-body .mail-info {
  background: #f3f4f4;
  display: table;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-bottom: 1px solid #ebebeb;
}

.mail-env .mail-body .mail-info:after, .mail-env .mail-body .mail-info:before {
  content: " ";
  display: table;
}

.mail-env .mail-body .mail-info:after {
  clear: both;
}

.mail-env .mail-body .mail-info .mail-date, .mail-env .mail-body .mail-info .mail-sender {
  display: table-cell;
  width: 50%;
  color: #a6a6a6;
  padding: 20px;
}

.mail-env .mail-body .mail-info .mail-date.mail-sender span, .mail-env .mail-body .mail-info .mail-sender.mail-sender span {
  font-weight: 700;
  color: #ec5956;
}

.mail-env .mail-body .mail-info .mail-date.mail-sender img, .mail-env .mail-body .mail-info .mail-sender.mail-sender img {
  margin-right: 5px;
  border: 2px solid #ebebeb;
}

.mail-env .mail-body .mail-info .mail-date.mail-sender .dropdown-menu:after, .mail-env .mail-body .mail-info .mail-sender.mail-sender .dropdown-menu:after {
  position: absolute;
  content: '';
  display: block;
  top: -5px;
  left: 17%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 4px;
  border-color: transparent transparent #cc2424;
}

.mail-env .mail-body .mail-info .mail-date.mail-date, .mail-env .mail-body .mail-info .mail-sender.mail-date {
  text-align: right;
}

.mail-env .mail-body .mail-text {
  border-bottom: 1px solid #ebebeb;
  padding: 20px;
}

.mail-env .mail-body .mail-attachments {
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
}

.mail-env .mail-body .mail-attachments h4 {
  margin-bottom: 30px;
  line-height: 1;
}

.mail-env .mail-body .mail-attachments h4 span {
  zoom: 1;
  -webkit-opacity: .4;
  -moz-opacity: .4;
  opacity: .4;
  filter: alpha(opacity=40);
}

.mail-env .mail-body .mail-attachments ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mail-env .mail-body .mail-attachments ul:after, .mail-env .mail-body .mail-attachments ul:before {
  content: " ";
  display: table;
}

.mail-env .mail-body .mail-attachments ul:after {
  clear: both;
}

.mail-env .mail-body .mail-attachments ul li {
  float: left;
  margin: 0 30px 20px 0;
  padding: 0;
}

.mail-env .mail-body .mail-attachments ul li > a {
  display: block;
}

.mail-env .mail-body .mail-attachments ul li > a img {
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}

.mail-env .mail-body .mail-attachments ul li > a.thumb {
  position: relative;
}

.mail-env .mail-body .mail-attachments ul li > a.thumb:after {
  font-family: Entypo;
  content: '\e826';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
  vertical-align: text-top;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 4px;
  -moz-background-clip: padding;
  border-radius: 4px;
  background-clip: padding-box;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 300ms ease-in-out;
}

.mail-env .mail-body .mail-attachments ul li > a.thumb:hover:after {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.mail-env .mail-body .mail-attachments ul li > a.thumb.download:after {
  content: '\e82d';
}

.mail-env .mail-body .mail-attachments ul li > a.name span {
  color: #666;
  float: right;
}

.mail-env .mail-body .mail-attachments ul li .links {
  display: block;
  font-size: 11px;
  color: #666;
  margin-top: 6px;
}

.mail-env .mail-body .mail-attachments ul li .links a {
  color: #666;
}

.mail-env .mail-body .mail-reply {
  border-bottom: 1px solid #ebebeb;
  padding: 20px;
}

.mail-env .mail-body .mail-reply .fake-form {
  padding: 20px;
  border: 2px solid #ebebeb;
  min-height: 100px;
  -webkit-border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 4px;
  -moz-background-clip: padding;
  border-radius: 4px;
  background-clip: padding-box;
}

.mail-env .mail-body .mail-reply .fake-form > div a {
  font-weight: 700;
  color: #ec5956;
}

.mail-env .mail-body .mail-compose {
  padding: 20px;
}

.mail-env .mail-body .mail-compose .form-group {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}

.mail-env .mail-body .mail-compose .form-group label {
  position: absolute;
  left: 10px;
  top: 7px;
  z-index: 10;
}

.mail-env .mail-body .mail-compose .form-group input {
  border-color: transparent;
  position: relative;
  z-index: 5;
  padding-left: 100px;
  transition: all 300ms ease-in-out;
}

.mail-env .mail-body .mail-compose .form-group input:focus {
  background: #f9f9f9;
  border-color: #f4f4f4;
}

.mail-env .mail-body .mail-compose .form-group .field-options {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 12;
}

.mail-env .mail-body .mail-compose .form-group .field-options a {
  display: inline-block;
  background: #f3f4f4;
  color: #7e8186;
  padding: 2px 6px;
  margin-left: 4px;
  text-align: center;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.mail-env .mail-body .mail-compose .compose-message-editor {
  padding-top: 5px;
}

.mail-env .mail-body .mail-compose .compose-message-editor textarea {
  height: 400px;
}

.mail-env .mail-body > div:last-child {
  border-bottom: 0;
}

.mail-env .mail-body .mail-table {
  margin-bottom: 0;
}

.mail-env .mail-body .mail-table tfoot tr th, .mail-env .mail-body .mail-table thead tr th {
  background: #ebebeb;
  border: 0;
  color: #666;
  vertical-align: middle;
  border-bottom: 0;
}

.mail-env .mail-body .mail-table tfoot tr th a, .mail-env .mail-body .mail-table thead tr th a {
  color: #666;
}

.mail-env .mail-body .mail-table tfoot tr th .mail-select-options, .mail-env .mail-body .mail-table thead tr th .mail-select-options {
  float: left;
  padding-top: 5px;
}

.mail-env .mail-body .mail-table tfoot tr th .mail-pagination, .mail-env .mail-body .mail-table thead tr th .mail-pagination {
  float: right;
}

.mail-env .mail-body .mail-table tfoot tr th .mail-pagination span, .mail-env .mail-body .mail-table thead tr th .mail-pagination span {
  color: rgba(102, 102, 102, 0.5);
}

.mail-env .mail-body .mail-table tfoot tr th .mail-pagination .btn-group, .mail-env .mail-body .mail-table thead tr th .mail-pagination .btn-group {
  margin-left: 5px;
}

.mail-env .mail-body .mail-table tfoot tr th .mail-pagination .btn-group .btn-sm, .mail-env .mail-body .mail-table thead tr th .mail-pagination .btn-group .btn-sm {
  padding-left: 7px;
  padding-right: 7px;
}

.mail-env .mail-body .mail-table tbody tr td {
  border-top: 0;
  border-bottom: 1px solid #ebebeb;
  height: 50px;
  vertical-align: middle;
  background: #f9f9f9;
}

.mail-env .mail-body .mail-table tbody tr td.col-name, .mail-env .mail-body .mail-table tbody tr td.col-subject {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail-env .mail-body .mail-table tbody tr td.col-name.col-subject a, .mail-env .mail-body .mail-table tbody tr td.col-subject.col-subject a {
  color: #8c8c8c;
}

.mail-env .mail-body .mail-table tbody tr td.col-name.col-subject a.label, .mail-env .mail-body .mail-table tbody tr td.col-subject.col-subject a.label {
  color: #fff;
}

.mail-env .mail-body .mail-table tbody tr td.col-name {
  width: 25%;
}

.mail-env .mail-body .mail-table tbody tr td.col-subject a {
  display: inline-block;
  max-width: 400px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail-env .mail-body .mail-table tbody tr td.col-options {
  text-align: right;
}

.mail-env .mail-body .mail-table tbody tr td.col-options a {
  color: #999;
}

.mail-env .mail-body .mail-table tbody tr td.col-time {
  width: 12%;
  text-align: right;
  color: rgba(102, 102, 102, 0.5);
}

.mail-env .mail-body .mail-table tbody tr td .star {
  display: inline-block;
  color: #ddd;
  margin-right: 5px;
  font-size: 14px;
  transition: all 300ms ease-in-out;
}

.mail-env .mail-body .mail-table tbody tr td .star.stared, .mail-env .mail-body .mail-table tbody tr td .star.starred {
  color: #ff9600;
}

.mail-env .mail-body .mail-table tbody tr td > .label:first-child {
  margin-left: 0;
}

.mail-env .mail-body .mail-table tbody tr.unread > td {
  background-color: #fff !important;
}

.mail-env .mail-body .mail-table tbody tr.unread > td.col-name a {
  font-weight: 700;
}

.mail-env .mail-body .mail-table tbody tr.highlight > td {
  background-color: #ffc !important;
}

.mail-env .mail-body .mail-table > tbody > tr > td:first-child, .mail-env .mail-body .mail-table > tbody > tr > th:first-child, .mail-env .mail-body .mail-table > tfoot > tr > td:first-child, .mail-env .mail-body .mail-table > tfoot > tr > th:first-child, .mail-env .mail-body .mail-table > thead > tr > td:first-child, .mail-env .mail-body .mail-table > thead > tr > th:first-child {
  padding-left: 20px;
}

.mail-env .mail-body .mail-table > tbody > tr > td:last-child, .mail-env .mail-body .mail-table > tbody > tr > th:last-child, .mail-env .mail-body .mail-table > tfoot > tr > td:last-child, .mail-env .mail-body .mail-table > tfoot > tr > th:last-child, .mail-env .mail-body .mail-table > thead > tr > td:last-child, .mail-env .mail-body .mail-table > thead > tr > th:last-child {
  padding-right: 20px;
}

.mail-env .mail-body .mail-table > tbody > tr > thX:first-child, .mail-env .mail-body .mail-table > tfoot > tr > thX:first-child, .mail-env .mail-body .mail-table > thead > tr > thX:first-child {
  position: relative;
}

.mail-env .mail-body .mail-table > tbody > tr > thX:first-child:before, .mail-env .mail-body .mail-table > tfoot > tr > thX:first-child:before, .mail-env .mail-body .mail-table > thead > tr > thX:first-child:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  background: #FFF;
}

.mail-env .mail-body .mail-table > tbody > tr > thX:last-child, .mail-env .mail-body .mail-table > tfoot > tr > thX:last-child, .mail-env .mail-body .mail-table > thead > tr > thX:last-child {
  position: relative;
}

.mail-env .mail-body .mail-table > tbody > tr > thX:last-child:before, .mail-env .mail-body .mail-table > tfoot > tr > thX:last-child:before, .mail-env .mail-body .mail-table > thead > tr > thX:last-child:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: #FFF;
}

.mail-env .mail-body .mail-table > tbody > tr:nth-child(odd) > td {
  background: #fbfbfb;
}

.mail-env .mail-body .mail-table .neon-cb-replacement {
  top: 2px;
}

.mail-env .mail-body .mail-table .neon-cb-replacement .cb-wrapper {
  background: #fff;
  border-color: #dfdfdf;
}

.mail-env .mail-body .mail-table .neon-cb-replacement .cb-wrapper .checked {
  background: #dfdfdf;
}

.mail-env.right-sidebar .mail-sidebar {
  border-left: 1px solid #ebebeb;
  border-right: 0;
}

.mail-env.right-sidebar .mail-body {
  float: left;
}

@media (max-width: 999px) {
  .mail-env .mail-body .mail-table tbody tr td.col-subject a {
    max-width: 280px;
  }
}

@media (max-width: 959px) {
  .mail-env .mail-body .mail-header div.mail-title {
    width: 100%;
    white-space: normal;
  }

  .mail-env .mail-body .mail-header .mail-links {
    float: none;
    width: 100%;
    text-align: left;
    clear: left;
    padding-top: 10px;
  }

  .mail-env .mail-body .mail-info {
    display: block;
  }

  .mail-env .mail-body .mail-info .mail-date, .mail-env .mail-body .mail-info .mail-sender {
    display: block;
    width: 100%;
  }

  .mail-env .mail-body .mail-info .mail-date.mail-sender, .mail-env .mail-body .mail-info .mail-sender.mail-sender {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .mail-env .mail-body .mail-info .mail-date.mail-date, .mail-env .mail-body .mail-info .mail-sender.mail-date {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mail-env .mail-body .mail-compose .compose-message-editor textarea {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .mail-env .mail-sidebar {
    width: 30.8%;
  }

  .mail-env .mail-body {
    width: 69.2%;
  }

  .mail-env .mail-body .mail-table tbody tr td.col-options {
    display: none;
  }

  .mail-env .mail-body .mail-table tbody tr td.col-subject a {
    max-width: 160px;
  }

  .mail-env .mail-body .mail-compose .compose-message-editor textarea {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .mail-env .mail-body, .mail-env .mail-sidebar {
    width: 100%;
    float: none;
  }

  .mail-env .mail-body .mail-header .mail-links, .mail-env .mail-body .mail-header .mail-search, .mail-env .mail-body .mail-header .mail-title {
    float: none;
    width: 100%;
  }

  .mail-env .mail-body .mail-header .mail-links.mail-links, .mail-env .mail-body .mail-header .mail-links.mail-search, .mail-env .mail-body .mail-header .mail-search.mail-links, .mail-env .mail-body .mail-header .mail-search.mail-search, .mail-env .mail-body .mail-header .mail-title.mail-links, .mail-env .mail-body .mail-header .mail-title.mail-search {
    margin-top: 20px;
  }

  .mail-env .mail-body .mail-header .mail-links {
    padding-top: 0;
  }
}

@media (max-width: 449px) {
  .mail-env .mail-body .mail-table tbody tr td.col-subject a {
    max-width: 40px;
  }

  .mail-env .mail-body .mail-table tbody tr > td.col-name a.star {
    display: none;
  }

  .mail-env .mail-body .mail-table > tbody > tr > td:last-child, .mail-env .mail-body .mail-table > tbody > tr > th:last-child, .mail-env .mail-body .mail-table > tfoot > tr > td:last-child, .mail-env .mail-body .mail-table > tfoot > tr > th:last-child, .mail-env .mail-body .mail-table > thead > tr > td:last-child, .mail-env .mail-body .mail-table > thead > tr > th:last-child {
    padding-right: 10px;
  }

  .mail-env .mail-body .mail-table > tbody > tr > td:first-child, .mail-env .mail-body .mail-table > tbody > tr > th:first-child, .mail-env .mail-body .mail-table > tfoot > tr > td:first-child, .mail-env .mail-body .mail-table > tfoot > tr > th:first-child, .mail-env .mail-body .mail-table > thead > tr > td:first-child, .mail-env .mail-body .mail-table > thead > tr > th:first-child {
    padding-left: 10px;
  }
}

.login-page {
  background: #303641;
}

.login-page .login-content {
  position: relative;
  width: 320px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  transition: all 550ms ease-in-out;
}

.login-page .login-content a {
  color: #949494;
}

.login-page .login-header {
  position: relative;
  background: #373e4a;
  padding: 100px 0;
  transition: all 550ms ease-in-out;
}

.login-page .login-header .description {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 0;
  transition: all 550ms ease-in-out;
}

.login-page .login-header.login-caret:after {
  position: absolute;
  content: '';
  left: 50%;
  margin-left: -12.5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 12.5px 0;
  border-color: #373e4a transparent transparent;
  bottom: -13px;
  transition: all 550ms ease-in-out;
}

.login-page .login-form {
  position: relative;
  padding-top: 60px;
  transition: all 550ms ease-in-out;
}

.login-page .login-form .form-group {
  margin-bottom: 20px;
  transition: all 550ms ease-in-out;
}

.login-page .login-form .form-group .input-group {
  background: #373e4a;
  border: 1px solid #373e4a;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: all 300ms ease-in-out;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.login-page .login-form .form-group .input-group.focused {
  border-color: #626f85;
  border-color: rgba(98, 111, 133, 0.5);
}

.login-page .login-form .form-group .input-group.validate-has-error {
  border-color: #ec5956;
  border-color: rgba(236, 89, 86, 0.5);
}

.login-page .login-form .form-group .input-group.validate-has-error .error {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
  font-size: 10px;
}

.login-page .login-form .form-group .input-group .form-control, .login-page .login-form .form-group .input-group .input-group-addon {
  background: 0 0;
  border: 0;
}

.login-page .login-form .form-group .input-group .input-group-addon {
  position: relative;
}

.login-page .login-form .form-group .input-group .input-group-addon:after {
  position: absolute;
  display: block;
  content: '';
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #454a54;
  transform: scaleY(0.56);
}

.login-page .login-form .form-group .input-group .form-control {
  color: #fff;
}

.login-page .login-form .form-group .input-group .form-control:focus {
  box-shadow: none;
}

.login-page .login-form .form-group.lockscreen-input {
  margin-top: -155px;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-thumb {
  position: relative;
  display: inline-block;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-thumb img {
  border: 5px solid #373e4a;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-thumb .lockscreen-progress-indicator {
  display: block;
  position: absolute;
  margin: 5px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 19px;
  text-align: center;
  line-height: 145px;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  transition: all 550ms ease-in-out;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-thumb canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-details {
  position: relative;
  padding-top: 5px;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-details h4 {
  color: #fff;
}

.login-page .login-form .form-group.lockscreen-input .lockscreen-details span {
  display: block;
  padding-bottom: 5px;
}

.login-page .login-form .form-group .btn-login {
  border: 1px solid #454a54;
  text-align: left;
  padding: 15px 20px;
  font-size: 14px;
  transition: all 300ms ease-in-out;
}

.login-page .login-form .form-group .btn-login i {
  float: right;
}

.login-page .login-form .form-group .btn-login:active, .login-page .login-form .form-group .btn-login:hover {
  background: #373e4a;
}

.login-page .login-form .form-group .facebook-button, .login-page .login-form .form-group .google-button, .login-page .login-form .form-group .twitter-button {
  text-align: left;
  color: #fff;
  background-color: #3b5998;
  font-size: 12px;
  transition: all 300ms ease-in-out;
}

.login-page .login-form .form-group .facebook-button i, .login-page .login-form .form-group .google-button i, .login-page .login-form .form-group .twitter-button i {
  background-color: #385490;
  transition: all 300ms ease-in-out;
}

.login-page .login-form .form-group .facebook-button:hover, .login-page .login-form .form-group .google-button:hover, .login-page .login-form .form-group .twitter-button:hover {
  background-color: rgba(59, 89, 152, 0.8);
}

.login-page .login-form .form-group .facebook-button:hover i, .login-page .login-form .form-group .google-button:hover i, .login-page .login-form .form-group .twitter-button:hover i {
  background-color: #31497e;
}

.login-page .login-form .form-group .facebook-button.twitter-button, .login-page .login-form .form-group .google-button.twitter-button, .login-page .login-form .form-group .twitter-button.twitter-button {
  background-color: #4099ff;
}

.login-page .login-form .form-group .facebook-button.twitter-button i, .login-page .login-form .form-group .google-button.twitter-button i, .login-page .login-form .form-group .twitter-button.twitter-button i {
  background-color: #0d7eff;
}

.login-page .login-form .form-group .facebook-button.twitter-button:hover, .login-page .login-form .form-group .google-button.twitter-button:hover, .login-page .login-form .form-group .twitter-button.twitter-button:hover {
  background-color: rgba(64, 153, 255, 0.8);
}

.login-page .login-form .form-group .facebook-button.twitter-button:hover i, .login-page .login-form .form-group .google-button.twitter-button:hover i, .login-page .login-form .form-group .twitter-button.twitter-button:hover i {
  background-color: #0071f3;
}

.login-page .login-form .form-group .facebook-button.google-button, .login-page .login-form .form-group .google-button.google-button, .login-page .login-form .form-group .twitter-button.google-button {
  background-color: #d34836;
}

.login-page .login-form .form-group .facebook-button.google-button i, .login-page .login-form .form-group .google-button.google-button i, .login-page .login-form .form-group .twitter-button.google-button i {
  background-color: #b03626;
}

.login-page .login-form .form-group .facebook-button.google-button:hover, .login-page .login-form .form-group .google-button.google-button:hover, .login-page .login-form .form-group .twitter-button.google-button:hover {
  background-color: rgba(211, 72, 54, 0.8);
}

.login-page .login-form .form-group .facebook-button.google-button:hover i, .login-page .login-form .form-group .google-button.google-button:hover i, .login-page .login-form .form-group .twitter-button.google-button:hover i {
  background-color: #9b3022;
}

.login-page .login-bottom-links {
  padding-top: 40px;
  padding-bottom: 30px;
}

.login-page .login-bottom-links a {
  transition: all 300ms ease-in-out;
}

.login-page .login-bottom-links a:hover {
  color: #aeaeae;
}

.login-page .login-bottom-links .link {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  transition: all 550ms ease-in-out;
}

.login-page .login-bottom-links .link:hover {
  color: #fff;
}

.login-page .login-progressbar {
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: all 550ms ease-in-out;
}

.login-page .login-progressbar div {
  width: 0;
  transition: 700ms all cubic-bezier(0.77, 0, 0.175, 1);
}

.login-page .login-progressbar-indicator {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 100%;
  margin-top: 80px;
  visibility: hidden;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translateY(100px) scale(0.2);
  transition: all 550ms ease-in-out;
}

.login-page .login-progressbar-indicator h3 {
  color: #fff;
  margin: 0 0 10px;
  font-size: 20px;
}

.login-page.logging-in {
  overflow: hidden;
}

.login-page.logging-in .login-header {
  padding-top: 170px;
  padding-bottom: 30px;
}

.login-page.logging-in .login-header .description, .login-page.logging-in .login-header.login-caret:after {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.login-page.logging-in .login-form .link, .login-page.logging-in .login-form form {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translateY(-200px) scale(0.8);
}

.login-page.logging-in .login-progressbar {
  background: #515b6d;
  height: 2px;
}

.login-page.logging-in .login-progressbar div {
  background: #de6c65;
  height: 2px;
}

.login-page.logging-in .login-progressbar-indicator {
  visibility: visible;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  transform: translateY(0) scale(1);
}

.login-page.logging-in-lockscreen .login-form .form-group.lockscreen-input .lockscreen-thumb .lockscreen-progress-indicator {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.login-page.logging-in-lockscreen .login-form .form-group:nth-child(n+2), .login-page.logging-in-lockscreen .login-form .link {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translateY(-50px) scale(0.5);
}

.login-page.login-form-fall .login-form {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  top: -100px;
  transition: all 550ms ease-in-out;
}

.login-page.login-form-fall .login-form .form-group {
  transition: all 550ms ease-in-out;
  transition-delay: 250ms;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.login-page.login-form-fall .login-form .form-group:nth-child(2) {
  transition-delay: 350ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(3) {
  transition-delay: 450ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(4) {
  transition-delay: 550ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(5) {
  transition-delay: 650ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(6) {
  transition-delay: 750ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(8) {
  transition-delay: 850ms;
}

.login-page.login-form-fall .login-form .form-group:nth-child(9) {
  transition-delay: 950ms;
}

.login-page.login-form-fall-init .login-form {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  top: 0;
}

.login-page.login-form-fall-init .login-form .form-group {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.login-page .form-steps .step {
  display: none;
}

.login-page .form-steps .step.current {
  display: block;
}

.login-page .form-forgotpassword-success, .login-page .form-login-error, .login-page .form-register-success {
  display: none;
  background: #00a651;
  color: #fff;
  padding: 10px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 30px;
  overflow: hidden;
}

.login-page .form-forgotpassword-success.visible, .login-page .form-login-error.visible, .login-page .form-register-success.visible {
  display: block;
}

.login-page .form-forgotpassword-success i, .login-page .form-login-error i, .login-page .form-register-success i {
  position: relative;
  font-size: 25px;
  background: #008d45;
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}

.login-page .form-forgotpassword-success h3, .login-page .form-login-error h3, .login-page .form-register-success h3 {
  font-size: 15px;
  color: #fff;
  margin: 10px 0 5px;
}

.login-page .form-forgotpassword-success p, .login-page .form-login-error p, .login-page .form-register-success p {
  font-size: 11px;
  margin: 0;
}

.login-page .form-forgotpassword-success.form-login-error, .login-page .form-login-error.form-login-error, .login-page .form-register-success.form-login-error {
  padding: 0;
  background: #cc2424;
}

.login-page .form-forgotpassword-success.form-login-error h3, .login-page .form-login-error.form-login-error h3, .login-page .form-register-success.form-login-error h3 {
  background: #b62020;
  padding: 10px;
  margin: 0 0 5px;
  font-size: 12px;
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}

.login-page .form-forgotpassword-success.form-login-error p, .login-page .form-login-error.form-login-error p, .login-page .form-register-success.form-login-error p {
  padding: 10px;
}

@media (max-width: 991px) {
  .login-page .login-header {
    padding: 20px 0;
  }

  .login-page .login-form {
    padding-top: 30px;
  }

  .login-page .login-form .form-group.lockscreen-input {
    margin-top: 0;
  }

  .login-page.logging-in .login-header {
    padding-top: 34px;
    padding-bottom: 6px;
  }

  .login-page.logging-in .login-progressbar-indicator {
    margin-top: 60px;
  }

  .login-page.logging-in .login-form .link, .login-page.logging-in .login-form form {
    transform: translateY(-100px) scale(0.6);
  }
}

@media (max-width: 350px) {
  .login-page .login-content {
    width: 280px;
  }
}

.tocify {
  border: 1px solid #f2f2f4;
  background: #fff;
  overflow: hidden;
  margin-top: 20px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.tocify.fixed {
  position: fixed;
  top: 10px;
}

.tocify li a:hover {
  background: #fafafb;
  color: #303641;
}

.tocify > ul {
  border-bottom: 1px solid #f2f2f4;
}

.tocify > ul ul li a {
  padding-left: 30px;
}

.tocify > ul ul {
  border-top: 1px solid #f2f2f4;
  background: #fdfdfd;
}

.tocify > ul:last-child {
  border-bottom: 0;
}

.tocify .tocify-item.active > a {
  background: #f8f8f8;
  font-weight: 700;
}

.tocify-extend-page {
  display: none !important;
}

@media (max-width: 991px) {
  .tocify.fixed {
    position: static;
    top: 0;
  }
}

hr + .calendar-env {
  margin-top: -18px;
  border-top: 1px solid #ebebeb;
  margin-left: -20px;
  margin-right: -20px;
}

.calendar-env + hr {
  margin-top: 0;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.calendar-env .calendar-body, .calendar-env .calendar-sidebar {
  float: left;
  box-sizing: border-box;
}

.calendar-env .calendar-body:after, .calendar-env .calendar-body:before, .calendar-env .calendar-sidebar:after, .calendar-env .calendar-sidebar:before {
  content: " ";
  display: table;
}

.calendar-env .calendar-body:after, .calendar-env .calendar-sidebar:after {
  clear: both;
}

.calendar-env .calendar-sidebar-row {
  padding: 20px;
}

.calendar-env > .calendar-sidebar-row.visible-xs {
  padding-bottom: 0;
}

.calendar-env .calendar-sidebar {
  width: 22%;
  background: #f9f9f9;
  border-right: 1px solid #ebebeb;
  position: relative;
}

.calendar-env .calendar-sidebar > h4 {
  padding: 20px;
}

.calendar-env .calendar-sidebar #add_event_form .input-group {
  background: #fff;
}

.calendar-env .calendar-sidebar .calendar-distancer {
  height: 40px;
}

.calendar-env .calendar-sidebar .events-list {
  border-top: 1px solid #ebebeb;
  list-style: none;
  margin: 0;
  padding: 20px;
}

.calendar-env .calendar-sidebar .events-list li a {
  display: block;
  padding: 6px 8px;
  margin-bottom: 4px;
  transition: background 250ms ease-in-out,color 250ms ease-in-out;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background: #ee4749;
  color: #fff;
}

.calendar-env .calendar-sidebar .events-list li a:hover {
  background: #ec3032;
}

.calendar-env .calendar-sidebar .events-list li a.color-blue {
  background: #21a9e1;
  color: #fff;
}

.calendar-env .calendar-sidebar .events-list li a.color-blue:hover {
  background: #1c99cd;
}

.calendar-env .calendar-sidebar .events-list li a.color-green {
  background: #00a651;
  color: #fff;
}

.calendar-env .calendar-sidebar .events-list li a.color-green:hover {
  background: #008d45;
}

.calendar-env .calendar-sidebar .events-list li a.color-primary {
  background: #303641;
  color: #fff;
}

.calendar-env .calendar-sidebar .events-list li a.color-primary:hover {
  background: #252a32;
}

.calendar-env .calendar-sidebar .events-list li a.color-orange {
  background: #ffae2f;
  color: #fff;
}

.calendar-env .calendar-sidebar .events-list li a.color-orange:hover {
  background: #ffa416;
}

.calendar-env .calendar-body {
  width: 78%;
  float: right;
}

.calendar-env .calendar-body .fc-header {
  border-bottom: 1px solid #ebebeb;
}

.calendar-env .calendar-body .fc-header h2, .calendar-env .calendar-body .fc-header h3 {
  margin: 0;
  padding: 0;
}

.calendar-env .calendar-body .fc-header .fc-header-left {
  padding: 20px;
}

.calendar-env .calendar-body .fc-header .fc-header-center {
  display: none;
}

.calendar-env .calendar-body .fc-header .fc-header-right {
  padding: 20px;
  text-align: right;
}

.calendar-env .calendar-body .fc-header .fc-button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0 !important;
  color: #303641;
  background-color: #fff;
  border-color: #ebebeb !important;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
  border-right-width: 0;
}

.calendar-env .calendar-body .fc-header .fc-button.active.focus, .calendar-env .calendar-body .fc-header .fc-button.active:focus, .calendar-env .calendar-body .fc-header .fc-button.focus, .calendar-env .calendar-body .fc-header .fc-button:active.focus, .calendar-env .calendar-body .fc-header .fc-button:active:focus, .calendar-env .calendar-body .fc-header .fc-button:focus {
  outline: 0 !important;
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px;
}

.calendar-env .calendar-body .fc-header .fc-button.focus, .calendar-env .calendar-body .fc-header .fc-button:focus, .calendar-env .calendar-body .fc-header .fc-button:hover {
  text-decoration: none;
}

.calendar-env .calendar-body .fc-header .fc-button.active, .calendar-env .calendar-body .fc-header .fc-button:active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.calendar-env .calendar-body .fc-header .fc-button.disabled, .calendar-env .calendar-body .fc-header .fc-button[disabled], fieldset[disabled] .calendar-env .calendar-body .fc-header .fc-button {
  cursor: not-allowed;
  zoom: 1;
  -webkit-opacity: .65;
  -moz-opacity: .65;
  opacity: .65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

a.calendar-env .calendar-body .fc-header .fc-button.disabled, fieldset[disabled] a.calendar-env .calendar-body .fc-header .fc-button {
  pointer-events: none;
}

.calendar-env .calendar-body .fc-header .fc-button:active {
  box-shadow: none;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon i {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.calendar-env .calendar-body .fc-header .fc-button.focus, .calendar-env .calendar-body .fc-header .fc-button:focus {
  color: #303641;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}

.calendar-env .calendar-body .fc-header .fc-button.active, .calendar-env .calendar-body .fc-header .fc-button:active, .calendar-env .calendar-body .fc-header .fc-button:hover, .open > .dropdown-toggle.calendar-env .calendar-body .fc-header .fc-button {
  color: #303641;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.calendar-env .calendar-body .fc-header .fc-button.active.focus, .calendar-env .calendar-body .fc-header .fc-button.active:focus, .calendar-env .calendar-body .fc-header .fc-button.active:hover, .calendar-env .calendar-body .fc-header .fc-button:active.focus, .calendar-env .calendar-body .fc-header .fc-button:active:focus, .calendar-env .calendar-body .fc-header .fc-button:active:hover, .open > .dropdown-toggle.calendar-env .calendar-body .fc-header .fc-button.focus, .open > .dropdown-toggle.calendar-env .calendar-body .fc-header .fc-button:focus, .open > .dropdown-toggle.calendar-env .calendar-body .fc-header .fc-button:hover {
  color: #303641;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}

.calendar-env .calendar-body .fc-header .fc-button.active, .calendar-env .calendar-body .fc-header .fc-button:active, .open > .dropdown-toggle.calendar-env .calendar-body .fc-header .fc-button {
  background-image: none;
}

.calendar-env .calendar-body .fc-header .fc-button.disabled.focus, .calendar-env .calendar-body .fc-header .fc-button.disabled:focus, .calendar-env .calendar-body .fc-header .fc-button.disabled:hover, .calendar-env .calendar-body .fc-header .fc-button[disabled].focus, .calendar-env .calendar-body .fc-header .fc-button[disabled]:focus, .calendar-env .calendar-body .fc-header .fc-button[disabled]:hover, fieldset[disabled] .calendar-env .calendar-body .fc-header .fc-button.focus, fieldset[disabled] .calendar-env .calendar-body .fc-header .fc-button:focus, fieldset[disabled] .calendar-env .calendar-body .fc-header .fc-button:hover {
  background-color: #fff;
  border-color: #fff;
}

.calendar-env .calendar-body .fc-header .fc-button .badge {
  color: #fff;
  background-color: #303641;
}

.calendar-env .calendar-body .fc-header .fc-button.focus, .calendar-env .calendar-body .fc-header .fc-button:focus, .calendar-env .calendar-body .fc-header .fc-button:hover {
  color: #303641 !important;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon i {
  background-color: #ebebeb;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-lg {
  padding-right: 55px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-sm {
  padding-right: 36px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-xs {
  padding-right: 32px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.calendar-env .calendar-body .fc-header .fc-button.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.calendar-env .calendar-body .fc-header .fc-button.fc-corner-left {
  -webkit-border-radius: 3px 0 0 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-header .fc-button.fc-corner-right {
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
  border-right-width: 1px;
}

.calendar-env .calendar-body .fc-header .fc-button.fc-corner-left.fc-corner-right {
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-header .fc-button.fc-state-active {
  background: #f5f5f6;
}

.calendar-env .calendar-body .fc-header .fc-header-space {
  width: 10px;
  display: inline-block;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-cell-overlay {
  background: rgba(255, 255, 204, 0.5);
  transition: all 300ms ease-in-out;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event {
  padding: 2px 4px;
  margin-top: 2px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background: #ee4749;
  color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event:hover {
  background: #ec3032;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-blue {
  background: #21a9e1;
  color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-blue:hover {
  background: #1c99cd;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-green {
  background: #00a651;
  color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-green:hover {
  background: #008d45;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-primary {
  background: #303641;
  color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-primary:hover {
  background: #252a32;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-orange {
  background: #ffae2f;
  color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view .fc-event.color-orange:hover {
  background: #ffa416;
}

.calendar-env .calendar-body .fc-content .fc-view table thead tr th {
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #ebebeb;
  background: #f5f5f6;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day {
  vertical-align: text-top;
  text-align: right;
  border-bottom: 1px solid #ebebeb;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day .fc-day-number {
  margin-top: 5px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day:hover {
  background-color: rgba(250, 250, 250, 0.68);
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number {
  color: #fff;
  background-color: #21a9e1;
  border-color: #1c99cd;
  display: inline-block;
  padding: 5px 8px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:focus {
  color: #fff;
  background-color: #1988b6;
  border-color: #0c455d;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.active, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:active, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:hover, .open > .dropdown-toggle.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number {
  color: #fff;
  background-color: #1988b6;
  border-color: #147197;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.active.focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.active:focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.active:hover, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:active.focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:active:focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:active:hover, .open > .dropdown-toggle.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.focus, .open > .dropdown-toggle.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:focus, .open > .dropdown-toggle.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:hover {
  color: #fff;
  background-color: #147197;
  border-color: #0c455d;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.active, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:active, .open > .dropdown-toggle.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number {
  background-image: none;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.disabled.focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.disabled:focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.disabled:hover, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number[disabled].focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number[disabled]:focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number[disabled]:hover, fieldset[disabled] .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.focus, fieldset[disabled] .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:focus, fieldset[disabled] .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:hover {
  background-color: #21a9e1;
  border-color: #1c99cd;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number .badge {
  color: #21a9e1;
  background-color: #fff;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:focus, .calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number:hover {
  color: #fff !important;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon i {
  background-color: #1a8fbf;
  padding: 6px;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-lg {
  padding-right: 55px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-lg i {
  padding: 10px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 3px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-sm {
  padding-right: 36px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-xs {
  padding-right: 32px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}

.calendar-env .calendar-body .fc-content .fc-view table tbody tr td.fc-day.fc-today .fc-day-number.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-days, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-days {
  border-bottom: 1px solid #e6e6e6;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-days + div, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-days + div {
  margin-top: 1px;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-days td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-days th, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-days td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-days th {
  width: 1% !important;
  color: #666;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-allday, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-allday {
  background: #fafafa;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-allday td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-allday th, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-allday td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-allday th {
  padding-top: 6px;
  padding-bottom: 6px;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-allday tbody tr .fc-agenda-axis, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-allday tbody tr .fc-agenda-axis {
  width: 60px !important;
  vertical-align: middle;
  text-align: right;
  color: #666;
  border-right: 1px solid #e8e8e8;
  padding-right: 6px;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-divider, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-divider {
  height: 2px;
  background: #ebebeb;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr th, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr th {
  border-bottom: 1px dotted #ebebeb;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr td.fc-agenda-axis, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr th.fc-agenda-axis, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr td.fc-agenda-axis, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr th.fc-agenda-axis {
  width: 60px !important;
  text-align: right;
  color: #666;
  border-right: 1px solid #e8e8e8;
  padding-right: 6px;
}

.calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr.fc-minor td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaDay .fc-agenda-slots tr.fc-minor th, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr.fc-minor td, .calendar-env .calendar-body .fc-content .fc-view.fc-view-agendaWeek .fc-agenda-slots tr.fc-minor th {
  border-bottom-color: #e6e6e6;
}

.calendar-env .calendar-body > div:last-child {
  border-bottom: 0;
}

.calendar-env.right-sidebar .calendar-sidebar {
  border-left: 1px solid #ebebeb;
  border-right: 0;
}

.calendar-env.right-sidebar .calendar-body {
  float: left;
}

@media (max-width: 959px) {
  .calendar-env .calendar-body .calendar-header div.calendar-title {
    width: 100%;
    white-space: normal;
  }

  .calendar-env .calendar-body .calendar-header .calendar-links {
    float: none;
    width: 100%;
    text-align: left;
    clear: left;
    padding-top: 10px;
  }

  .calendar-env .calendar-body .calendar-info {
    display: block;
  }

  .calendar-env .calendar-body .calendar-info .calendar-date, .calendar-env .calendar-body .calendar-info .calendar-sender {
    display: block;
    width: 100%;
  }

  .calendar-env .calendar-body .calendar-info .calendar-date.calendar-sender, .calendar-env .calendar-body .calendar-info .calendar-sender.calendar-sender {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .calendar-env .calendar-body .calendar-info .calendar-date.calendar-date, .calendar-env .calendar-body .calendar-info .calendar-sender.calendar-date {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .calendar-env .calendar-body .calendar-compose .compose-message-editor textarea {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .calendar-env .calendar-sidebar {
    width: 30.8%;
  }

  .calendar-env .calendar-body {
    width: 69.2%;
  }

  .calendar-env .calendar-body .calendar-compose .compose-message-editor textarea {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .calendar-env .calendar-body, .calendar-env .calendar-sidebar {
    width: 100%;
    float: none;
  }

  .calendar-env .calendar-body .calendar-header .calendar-links, .calendar-env .calendar-body .calendar-header .calendar-search, .calendar-env .calendar-body .calendar-header .calendar-title {
    float: none;
    width: 100%;
  }

  .calendar-env .calendar-body .calendar-header .calendar-links.calendar-links, .calendar-env .calendar-body .calendar-header .calendar-links.calendar-search, .calendar-env .calendar-body .calendar-header .calendar-search.calendar-links, .calendar-env .calendar-body .calendar-header .calendar-search.calendar-search, .calendar-env .calendar-body .calendar-header .calendar-title.calendar-links, .calendar-env .calendar-body .calendar-header .calendar-title.calendar-search {
    margin-top: 20px;
  }

  .calendar-env .calendar-body .calendar-header .calendar-links {
    padding-top: 0;
  }

  .fc-header {
    display: block;
  }

  .fc-header .fc-header-center, .fc-header .fc-header-left, .fc-header .fc-header-right, .fc-header tbody, .fc-header tr {
    display: block;
    text-align: center !important;
  }

  .fc-header .fc-header-right {
    text-align: center !important;
    padding-bottom: 10px;
  }
}

.calendar-env {
  position: relative;
}

.calendar-env:after, .calendar-env:before {
  content: " ";
  display: table;
}

.calendar-env:after {
  clear: both;
}

.calendar-env .fc .fc-toolbar {
  padding: 20px;
  margin: 0;
}

.calendar-env .fc .fc-toolbar h2, .calendar-env .fc .fc-toolbar h3 {
  margin: 0;
  padding: 0;
}

.calendar-env .fc .fc-toolbar .fc-button {
  background: 0 0;
  border: 1px solid #ebebeb;
  height: auto;
  padding: 5px 10px;
  box-shadow: none;
  outline: 0;
}

.calendar-env .fc .fc-toolbar .fc-button .fc-icon {
  top: 0;
  margin: 0;
}

.calendar-env .fc .fc-toolbar .fc-button .fc-icon:after {
  font-weight: 400;
  font-size: 16px;
}

.calendar-env .fc .fc-view-container > .fc-view {
  border-left: 1px solid #ebebeb;
}

.calendar-env .fc .fc-view-container .fc-widget-content, .calendar-env .fc .fc-view-container .fc-widget-header {
  border: 0;
}

.calendar-env .fc .fc-view-container .fc-widget-header .fc-widget-header {
  border: 1px solid #ebebeb;
  border-left: 0;
  border-right: 0;
  box-shadow: none;
}

.calendar-env .fc .fc-view-container .fc-view table thead {
  border-left: 0;
  border-right: 0;
}

.calendar-env .fc .fc-view-container .fc-view table thead tr th {
  text-align: center;
  padding: 5px 0;
  border: 0;
  border-bottom: 1px solid #ebebeb;
  background: #f5f5f6;
}

.calendar-env .fc .fc-view-container .fc-view table tbody {
  border: 0;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-day {
  border: 0;
  border-top: 1px solid #ebebeb;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-day.fc-state-highlight {
  background: #fafafa;
  color: #111 !important;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-day-number {
  padding: 5px 10px;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event {
  padding: 2px 4px;
  margin-top: 2px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background: #ee4749;
  color: #fff;
  border: 0;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event:hover {
  background: #ec3032;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-blue {
  background: #21a9e1;
  color: #fff;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-blue:hover {
  background: #1c99cd;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-green {
  background: #00a651;
  color: #fff;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-green:hover {
  background: #008d45;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-primary {
  background: #303641;
  color: #fff;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-primary:hover {
  background: #252a32;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-orange {
  background: #ffae2f;
  color: #fff;
}

.calendar-env .fc .fc-view-container .fc-view table tbody .fc-event.color-orange:hover {
  background: #ffa416;
}

.calendar-env .fc .fc-view-container .fc-view.fc-agenda-view table .fc-widget-content {
  border-bottom: 1px solid #ebebeb;
}

.calendar-env .fc .fc-view-container .fc-view.fc-agenda-view table .fc-axis {
  border-right: 1px solid #ebebeb;
}

.notes-env {
  position: relative;
}

.notes-env .notes-header {
  display: table;
  width: 100%;
  vertical-align: middle;
}

.notes-env .notes-header:after, .notes-env .notes-header:before {
  content: " ";
  display: table;
}

.notes-env .notes-header:after {
  clear: both;
}

.notes-env .notes-header > .right, .notes-env .notes-header > h2 {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  margin: 0;
  padding-bottom: 20px;
}

.notes-env .notes-header > .right {
  text-align: right;
}

.notes-env .notes-list {
  position: relative;
  background: #f0ecdb;
  margin-left: -20px;
  margin-right: -20px;
  border: 1px solid #ebebeb;
  border-left: 0;
  border-right: 0;
}

.notes-env .notes-list:after, .notes-env .notes-list:before {
  content: " ";
  display: table;
}

.notes-env .notes-list:after {
  clear: both;
}

.notes-env .notes-list .list-of-notes, .notes-env .notes-list .write-pad {
  box-sizing: border-box;
}

.notes-env .notes-list .list-of-notes {
  float: right;
  width: 26%;
  list-style: none;
  margin: 0;
  padding: 0 0 30px;
}

.notes-env .notes-list .list-of-notes li {
  position: relative;
  padding: 30px;
  padding-bottom: 0;
}

.notes-env .notes-list .list-of-notes li a {
  display: block;
  border: 1px solid #e9e4ca;
  background: #fffced;
  padding: 20px;
  transition: all 300ms ease-in-out;
}

.notes-env .notes-list .list-of-notes li a span, .notes-env .notes-list .list-of-notes li a strong {
  display: block;
}

.notes-env .notes-list .list-of-notes li a span + span, .notes-env .notes-list .list-of-notes li a span + strong, .notes-env .notes-list .list-of-notes li a strong + span, .notes-env .notes-list .list-of-notes li a strong + strong {
  padding-top: 8px;
}

.notes-env .notes-list .list-of-notes li a strong {
  word-break: break-all;
}

.notes-env .notes-list .list-of-notes li a span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notes-env .notes-list .list-of-notes li a em {
  float: right;
}

.notes-env .notes-list .list-of-notes li a:hover {
  background: #eae6ce;
  border-color: #dad2a6;
}

.notes-env .notes-list .list-of-notes li a:hover span, .notes-env .notes-list .list-of-notes li a:hover strong {
  color: #737881;
}

.notes-env .notes-list .list-of-notes li .note-close {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  background: 0 0;
  outline: 0;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 300ms ease-in-out;
}

.notes-env .notes-list .list-of-notes li:hover .note-close {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.notes-env .notes-list .list-of-notes li .content {
  display: none;
}

.notes-env .notes-list .list-of-notes li.current a {
  background: #fff;
}

.notes-env .notes-list .list-of-notes li + .no-notes {
  display: none;
}

.notes-env .notes-list .write-pad {
  float: left;
  width: 74%;
  background: #fffced;
  position: relative;
}

.notes-env .notes-list .write-pad:after {
  display: block;
  content: '';
  position: absolute;
  left: 95px;
  top: 0;
  bottom: 0;
  background: #f9d4d1;
  width: 1px;
}

.notes-env .notes-list .write-pad textarea {
  border: none;
  background: url(//assets/images/notes-lines.png) left top local;
  min-height: 780px;
  font: 14px/52px "Helvetica Neue",Helvetica,"Noto Sans",sans-serif,serif;
  max-height: 1500px;
  padding-left: 125px;
  padding-right: 50px;
}

.notes-env .notes-list .write-pad textarea::-webkit-scrollbar {
  width: 5px;
}

.notes-env .notes-list .write-pad textarea::-webkit-scrollbar-track {
  width: 5px;
  background-color: #e8e3c9;
}

.notes-env .notes-list .write-pad textarea::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}

.notes-env + footer.main {
  border-top: 0;
  padding-top: 10px;
}

@media (max-width: 992px) {
  .notes-env .notes-list .list-of-notes {
    width: 35%;
  }

  .notes-env .notes-list .write-pad {
    width: 65%;
  }

  .notes-env .notes-list .write-pad textarea {
    padding-left: 50px;
  }

  .notes-env .notes-list .write-pad:after {
    left: 35px;
  }
}

@media (max-width: 768px) {
  body .notes-env .notes-list .list-of-notes, body .notes-env .notes-list .write-pad {
    width: 100%;
    float: none;
  }

  body .notes-env .notes-list .list-of-notes {
    padding-bottom: 10px;
  }

  body .notes-env .notes-list .list-of-notes li {
    padding: 10px 15px 15px;
    padding-bottom: 0;
  }

  body .notes-env .notes-list .list-of-notes li a span {
    display: none;
  }

  body .notes-env .notes-list .write-pad textarea {
    min-height: 400px;
    max-height: 600px;
  }
}

.gallery-env:after, .gallery-env:before {
  content: " ";
  display: table;
}

.gallery-env:after {
  clear: both;
}

.gallery-env article.album {
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.gallery-env article.album header {
  position: relative;
}

.gallery-env article.album header img {
  line-height: 1;
  margin: 0;
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}

.gallery-env article.album header .album-options {
  position: absolute;
  display: block;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 7px;
  font-size: 11px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  transition: all 300ms ease-in-out;
}

.gallery-env article.album header:hover .album-options {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-env article.album .album-info {
  padding: 20px;
}

.gallery-env article.album .album-info h3 {
  font-size: 18px;
  margin: 0;
}

.gallery-env article.album .album-info p {
  margin: 10px 0 0;
  color: #80858e;
}

.gallery-env article.album footer {
  border-top: 1px solid #f0f0f0;
}

.gallery-env article.album footer:after, .gallery-env article.album footer:before {
  content: " ";
  display: table;
}

.gallery-env article.album footer:after {
  clear: both;
}

.gallery-env article.album footer .album-images-count, .gallery-env article.album footer .album-options {
  padding: 12px 15px;
  float: left;
  color: #8d929a;
}

.gallery-env article.album footer .album-images-count a, .gallery-env article.album footer .album-options a {
  color: #8d929a;
  display: inline-block;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.gallery-env article.album footer .album-images-count a + a, .gallery-env article.album footer .album-options a + a {
  margin-left: 10px;
}

.gallery-env article.album footer .album-options {
  float: right;
  border-left: 1px solid #f0f0f0;
  padding: 7px 10px;
}

.gallery-env article.album footer .album-options a {
  padding: 4px 5px;
  transition: all 300ms ease-in-out;
}

.gallery-env article.album footer .album-options a:hover {
  background: #f5f5f5;
  color: #43464b;
}

.gallery-env article.image-thumb {
  margin-bottom: 20px;
}

.gallery-env article.image-thumb .image {
  position: relative;
}

.gallery-env article.image-thumb .image img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  width: 100%;
}

.gallery-env article.image-thumb .image-options {
  position: absolute;
  top: -10px;
  right: 8px;
  transform: scale(0);
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform-origin: 50% 0;
  transition: all 300ms ease-in-out;
}

.gallery-env article.image-thumb .image-options a {
  display: inline-block;
  margin-left: 2px;
  background: #737881;
  color: #FFF;
  width: 24px;
  height: 24px;
  line-height: 24px;
  -webkit-border-radius: 12px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 12px;
  -moz-background-clip: padding;
  border-radius: 12px;
  background-clip: padding-box;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.gallery-env article.image-thumb .image-options a.delete {
  background: #dd1f26;
}

.gallery-env article.image-thumb:hover .image-options {
  transform: scale(1);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-env div[data-tag] {
  transition: opacity 350ms ease-in-out;
}

.gallery-env div[data-tag].not-in-filter {
  zoom: 1;
  -webkit-opacity: .3;
  -moz-opacity: .3;
  opacity: .3;
  filter: alpha(opacity=30);
}

.gallery-env div[data-tag].no-animation {
  transition: none;
}

.gallery-env .image-categories {
  margin-bottom: 20px;
  background: #fafafb;
  position: relative;
  margin-top: -17px;
  padding: 10px;
}

.gallery-env .image-categories span {
  color: #80858e;
}

.gallery-env .image-categories a {
  display: inline-block;
  margin: 0 5px;
  transition: all 300ms ease-in-out;
}

.gallery-env .image-categories a.active {
  font-weight: 700;
  color: #4f5259;
}

.modal .croppable-image {
  border: 4px solid rgba(235, 235, 235, 0.5);
}

.modal h4 + .croppable-image {
  margin-top: 5px;
}

.jcrop-keymgr {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.gallery-image-edit-env {
  border-bottom: 1px solid #ebebeb;
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
}

.gallery-image-edit-env img {
  width: 100%;
}

.gallery-image-edit-env .close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
  display: block;
  z-index: 1000;
  zoom: 1;
  -webkit-opacity: .7;
  -moz-opacity: .7;
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  transition: all 300ms ease-in-out;
}

.gallery-image-edit-env .close:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-image-edit-env .jcrop-holder {
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}

.profile-env > header {
  position: relative;
  z-index: 20;
  margin-top: 30px;
}

.profile-env > header .profile-picture {
  position: relative;
}

.profile-env > header .profile-picture img {
  float: right;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
  transition: all 300ms ease-in-out;
}

.profile-env > header .profile-picture:hover img {
  zoom: 1;
  -webkit-opacity: .5;
  -moz-opacity: .5;
  opacity: .5;
  filter: alpha(opacity=50);
}

.profile-env > header .profile-info-sections {
  margin: 15px 0 0 -5px;
  padding: 0;
  list-style: none;
}

.profile-env > header .profile-info-sections > li {
  display: inline-block;
}

.profile-env > header .profile-info-sections .profile-name span, .profile-env > header .profile-info-sections .profile-name strong {
  display: block;
}

.profile-env > header .profile-info-sections .profile-name strong {
  font-size: 18px;
  font-weight: 400;
}

.profile-env > header .profile-info-sections .profile-name span {
  font-size: 12px;
  color: #bbb;
}

.profile-env > header .profile-info-sections .profile-name span a {
  color: #bbb;
  transition: all 300ms ease-in-out;
}

.profile-env > header .profile-info-sections .profile-name span a:hover {
  color: #888;
}

.profile-env > header .profile-info-sections .profile-name .user-status {
  position: relative;
  display: inline-block;
  background: #575d67;
  top: -2px;
  margin-left: 5px;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 6px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 6px;
  -moz-background-clip: padding;
  border-radius: 6px;
  background-clip: padding-box;
  transition: all 300ms ease-in-out;
}

.profile-env > header .profile-info-sections .profile-name .user-status.is-online {
  background-color: #06b53c;
}

.profile-env > header .profile-info-sections .profile-name .user-status.is-offline {
  background-color: #575d67;
}

.profile-env > header .profile-info-sections .profile-name .user-status.is-idle {
  background-color: #f7d227;
}

.profile-env > header .profile-info-sections .profile-name .user-status.is-busy {
  background-color: #ee4749;
}

.profile-env > header .profile-info-sections .profile-stat h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-env > header .profile-info-sections .profile-stat span {
  color: #bbb;
}

.profile-env > header .profile-info-sections .profile-stat span a {
  color: #bbb;
  transition: all 300ms ease-in-out;
}

.profile-env > header .profile-info-sections .profile-stat span a:hover {
  color: #888;
}

.profile-env > header .profile-info-sections > li {
  padding: 0 40px;
  position: relative;
}

.profile-env > header .profile-info-sections > li + li:after {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  width: 1px;
  background: #ebebeb;
  margin: 8px 0;
}

.profile-env > header .profile-info-sections > li:first-child {
  padding-left: 0;
}

.profile-env > header .profile-buttons {
  margin-top: 35px;
}

.profile-env > header .profile-buttons a {
  margin: 0 4px;
  transition: all 300ms ease-in-out;
}

.profile-env section {
  position: relative;
  z-index: 10;
}

.profile-env section.profile-info-tabs {
  position: relative;
  background: #f1f1f1;
  margin: -20px -20px 30px;
  padding: 20px;
}

.profile-env section.profile-info-tabs .user-details {
  padding-left: 0;
  list-style: none;
}

.profile-env section.profile-info-tabs .user-details li {
  margin-bottom: 10px;
}

.profile-env section.profile-info-tabs .user-details li a {
  color: #a0a0a0;
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-info-tabs .user-details li a:hover {
  color: #606060;
}

.profile-env section.profile-info-tabs .user-details li a:hover span {
  color: #e72c28;
}

.profile-env section.profile-info-tabs .user-details li a i {
  margin-right: 5px;
}

.profile-env section.profile-info-tabs .user-details li a span {
  color: #ec5956;
  font-weight: 400;
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-info-tabs .nav-tabs {
  position: relative;
  margin-bottom: -20px;
  border-bottom: 0;
}

.profile-env section.profile-info-tabs .nav-tabs > li:first-child a {
  margin-left: 0;
}

.profile-env section.profile-info-tabs .nav-tabs li {
  margin-bottom: 0;
}

.profile-env section.profile-info-tabs .nav-tabs li a {
  border: none;
  padding: 10px 35px;
  font-size: 13px;
  background: #e1e1e1;
  margin-right: 10px;
}

.profile-env section.profile-info-tabs .nav-tabs li.active a {
  background: #fff;
}

.profile-env section.profile-feed {
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.profile-env section.profile-feed .profile-post-form {
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.profile-env section.profile-feed .profile-post-form .form-control {
  border: none;
  box-shadow: none;
  margin: 0;
  background: #fff;
  min-height: 80px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.profile-env section.profile-feed .profile-post-form .form-options {
  background: #f3f3f3;
  border-top: 1px solid #ebebeb;
  padding: 10px;
}

.profile-env section.profile-feed .profile-post-form .form-options:after, .profile-env section.profile-feed .profile-post-form .form-options:before {
  content: " ";
  display: table;
}

.profile-env section.profile-feed .profile-post-form .form-options:after {
  clear: both;
}

.profile-env section.profile-feed .profile-post-form .form-options .post-type {
  float: left;
  padding-top: 6px;
}

.profile-env section.profile-feed .profile-post-form .form-options .post-type a {
  margin-left: 10px;
  font-size: 13px;
  color: #aaa;
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-feed .profile-post-form .form-options .post-type a:hover {
  color: #303641;
}

.profile-env section.profile-feed .profile-post-form .form-options .post-submit {
  float: right;
}

.profile-env section.profile-feed .profile-post-form .form-options .post-submit .btn {
  padding-left: 20px;
  padding-right: 20px;
}

.profile-env section.profile-feed .profile-stories article.story {
  margin: 30px 0;
}

.profile-env section.profile-feed .profile-stories article.story:after, .profile-env section.profile-feed .profile-stories article.story:before {
  content: " ";
  display: table;
}

.profile-env section.profile-feed .profile-stories article.story:after {
  clear: both;
}

.profile-env section.profile-feed .profile-stories article.story .user-thumb {
  float: left;
  width: 8%;
}

.profile-env section.profile-feed .profile-stories article.story .user-thumb a img {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.04);
}

.profile-env section.profile-feed .profile-stories article.story .story-content {
  float: right;
  width: 92%;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header {
  display: block;
  margin-bottom: 10px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header:after, .profile-env section.profile-feed .profile-stories article.story .story-content header:before {
  content: " ";
  display: table;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header:after {
  clear: both;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header .publisher {
  float: left;
  color: #9b9fa6;
  font-size: 14px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header .publisher a {
  color: #303641;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header .publisher em {
  display: block;
  font-style: normal;
  font-size: 12px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content header .story-type {
  float: right;
}

.profile-env section.profile-feed .profile-stories article.story .story-content .story-main-content, .profile-env section.profile-feed .profile-stories article.story .story-content .story-main-content p {
  font-size: 13px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer {
  margin-top: 15px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .liked i {
  color: #ff4e50;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer > a {
  margin-right: 30px;
  display: inline-block;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer > a span {
  zoom: 1;
  -webkit-opacity: .6;
  -moz-opacity: .6;
  opacity: .6;
  filter: alpha(opacity=60);
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments {
  list-style: none;
  margin: 30px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #ebebeb;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li {
  display: table;
  vertical-align: top;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li:after, .profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li:before {
  content: " ";
  display: table;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li:after {
  clear: both;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li + li {
  margin-top: 15px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content, .profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-thumb {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-thumb {
  width: 1%;
  padding-right: 20px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-name {
  font-weight: 700;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta {
  font-size: 11px;
  margin-top: 15px;
  color: #9b9fa6;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta a {
  color: #8d929a;
  margin-right: 5px;
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta a + a {
  margin-left: 5px;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta a i {
  zoom: 1;
  -webkit-opacity: .8;
  -moz-opacity: .8;
  opacity: .8;
  filter: alpha(opacity=80);
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta a:hover {
  color: #737881;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li .user-comment-content .user-comment-meta a:hover i {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li.comment-form .user-comment-content {
  position: relative;
  border-bottom: 0;
  padding-bottom: 0;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li.comment-form .user-comment-content .form-control {
  background: #eee;
  border: 0;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li.comment-form .user-comment-content .btn {
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  background: 0 0;
  color: #737881;
  font-size: 13px;
  zoom: 1;
  -webkit-opacity: .7;
  -moz-opacity: .7;
  opacity: .7;
  filter: alpha(opacity=70);
  transition: all 300ms ease-in-out;
}

.profile-env section.profile-feed .profile-stories article.story .story-content footer .comments li.comment-form .user-comment-content .btn:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.profile-env section.profile-feed .profile-stories article.story .story-content hr {
  margin-top: 40px;
}

@media (max-width: 992px) {
  .profile-env > header .profile-picture img {
    width: 90%;
  }

  .profile-env > header .profile-buttons {
    margin-top: 18px;
  }

  .profile-env > header .profile-info-sections .profile-name strong, .profile-env > header .profile-info-sections .profile-stat h3 {
    font-size: 16px;
  }

  .profile-env > header .profile-info-sections {
    margin-top: 0;
  }

  .profile-env > header .profile-info-sections > li {
    padding: 0 20px;
  }

  .profile-env section.profile-info-tabs .nav-tabs li a {
    padding-left: 25px;
    padding-right: 25px;
  }

  .profile-env section.profile-feed .profile-stories article.story .user-thumb {
    width: 10%;
  }

  .profile-env section.profile-feed .profile-stories article.story .story-content {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .profile-env section.profile-info-tabs {
    margin-top: 30px;
  }

  .profile-env > header .profile-picture img {
    float: none;
  }

  .profile-env > header .profile-buttons a {
    margin-bottom: 5px;
  }
}

@media (max-width: 601px) {
  .profile-env > header .profile-info-sections {
    margin-bottom: 10px;
  }

  .profile-env > header .profile-info-sections li {
    padding: 15px;
  }

  .profile-env > header .profile-info-sections > li:first-child {
    padding-left: 0;
  }

  .profile-env > header .profile-buttons {
    margin-top: 0;
  }

  .profile-env > header .profile-picture {
    text-align: center;
    display: block;
  }

  .profile-env > header .profile-picture img {
    width: auto;
    float: none;
    display: inline-block;
    margin-bottom: 15px;
  }

  .profile-env section.profile-feed .profile-stories article.story .user-thumb {
    width: 18%;
  }

  .profile-env section.profile-feed .profile-stories article.story .story-content {
    width: 82%;
  }

  .profile-env section.profile-info-tabs .nav-tabs li a {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    font-size: 12px;
  }

  .profile-env section.profile-feed {
    padding: 0;
  }

  .profile-env .col-sm-3, .profile-env .col-sm-7 {
    text-align: center;
  }

  .profile-env .col-sm-3 .profile-buttons, .profile-env .col-sm-3 .profile-info-sections, .profile-env .col-sm-7 .profile-buttons, .profile-env .col-sm-7 .profile-info-sections {
    display: inline-block;
  }

  .profile-env > header .profile-info-sections > li + li:after {
    margin: 18px 0;
  }
}

.map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  box-shadow: 0 0 0 1px #ebebeb;
  background: #fff !important;
}

.page-body .main-content .cbp_tmtimeline:before {
  background: #f5f5f6;
  width: 5px;
  margin-left: -6px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span {
  color: #111;
  font-size: 15px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span:first-child {
  font-weight: 700;
  margin-bottom: 2px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span:last-child {
  color: #303641;
  zoom: 1;
  -webkit-opacity: .8;
  -moz-opacity: .8;
  opacity: .8;
  filter: alpha(opacity=80);
  font-size: 12px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span.large {
  font-size: 17px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span.hidden + span {
  margin-top: 8px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon {
  background: #fff;
  color: #d2d2d2;
  box-shadow: 0 0 0 5px #f5f5f6;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-primary {
  background-color: #303641;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-secondary {
  background-color: #ee4749;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-success {
  background-color: #00a651;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-info {
  background-color: #21a9e1;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-warning {
  background-color: #fad839;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.bg-danger {
  background-color: #cc2424;
  color: #fff;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel {
  background: #f5f5f6;
  color: #737881;
  margin-bottom: 70px;
  padding: 1.7em;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2, .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel p {
  color: #737881;
  font-family: "Helvetica Neue",Helvetica,"Noto Sans",sans-serif;
  font-size: 12px;
  margin: 0;
  line-height: 1.42857143;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel p + p {
  margin-top: 15px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 {
  font-size: 16px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 a {
  color: #303641;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 span {
  zoom: 1;
  -webkit-opacity: .6;
  -moz-opacity: .6;
  opacity: .6;
  filter: alpha(opacity=60);
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel:after {
  border-right-color: #f5f5f6;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty {
  background: 0;
  padding: 9px 0;
  margin-bottom: 70px;
}

.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty:after {
  visibility: hidden;
}

@media screen and (max-width: 47.2em) {
  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
    left: 10px;
  }

  .page-body .main-content .cbp_tmtimeline > li .large {
    font-weight: 700;
    font-size: 16px !important;
  }

  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty {
    background: #f5f5f6;
    padding: 1.7em;
  }

  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty:after {
    visibility: visible;
  }
}

.timeline-centered {
  position: relative;
  margin-bottom: 30px;
}

.timeline-centered:after, .timeline-centered:before {
  content: " ";
  display: table;
}

.timeline-centered:after {
  clear: both;
}

.timeline-centered:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #f5f5f6;
  left: 50%;
  top: 20px;
  bottom: 20px;
  margin-left: -4px;
}

.timeline-centered .timeline-entry {
  position: relative;
  width: 50%;
  float: right;
  margin-bottom: 70px;
  clear: both;
}

.timeline-centered .timeline-entry:after, .timeline-centered .timeline-entry:before {
  content: " ";
  display: table;
}

.timeline-centered .timeline-entry:after {
  clear: both;
}

.timeline-centered .timeline-entry.begin {
  margin-bottom: 0;
}

.timeline-centered .timeline-entry.left-aligned {
  float: left;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
  margin-left: 0;
  margin-right: -18px;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
  text-align: left;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
  float: right;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
  margin-left: 0;
  margin-right: 70px;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -9px;
  transform: rotate(180deg);
}

.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -22px;
}

.timeline-centered .timeline-entry .timeline-entry-inner:after, .timeline-centered .timeline-entry .timeline-entry-inner:before {
  content: " ";
  display: table;
}

.timeline-centered .timeline-entry .timeline-entry-inner:after {
  clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
  position: absolute;
  left: -100px;
  text-align: right;
  padding: 10px;
  box-sizing: border-box;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
  display: block;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
  font-size: 15px;
  font-weight: 700;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
  font-size: 12px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  background: #fff;
  color: #737881;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 20px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 20px;
  -moz-background-clip: padding;
  border-radius: 20px;
  background-clip: padding-box;
  text-align: center;
  box-shadow: 0 0 0 5px #f5f5f6;
  line-height: 40px;
  font-size: 15px;
  float: left;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
  background-color: #303641;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-secondary {
  background-color: #ee4749;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
  background-color: #00a651;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
  background-color: #21a9e1;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
  background-color: #fad839;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
  background-color: #cc2424;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
  position: relative;
  background: #f5f5f6;
  padding: 1.7em;
  margin-left: 70px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #f5f5f6 transparent transparent;
  left: 0;
  top: 10px;
  margin-left: -9px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
  color: #737881;
  font-family: "Helvetica Neue",Helvetica,"Noto Sans",sans-serif;
  font-size: 12px;
  margin: 0;
  line-height: 1.42857143;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
  margin-top: 15px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
  color: #303641;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
  zoom: 1;
  -webkit-opacity: .6;
  -moz-opacity: .6;
  opacity: .6;
  filter: alpha(opacity=60);
}

@media screen and (max-width: 47.2em) {
  .timeline-centered {
    margin-top: 20px;
  }

  .timeline-centered:before {
    left: 22px;
  }

  .timeline-centered .timeline-entry, .timeline-centered .timeline-entry.left-aligned {
    width: 100%;
    float: none;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner, .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
    margin-left: 0;
    margin-right: 0;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time, .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
    left: 60px;
    right: auto;
    top: -40px;
    width: auto;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time span, .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time span {
    display: inline-block;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time span + span, .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time span + span {
    margin-left: 10px;
  }

  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
    float: left;
  }

  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
    margin-left: 70px;
    margin-right: 0;
  }

  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
    left: 0;
    right: auto;
    margin-left: -9px;
    margin-right: 0;
    transform: rotate(0deg);
  }
}

@media screen and (min-width: 1700px) {
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 23% !important;
  }
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
  white-space: nowrap;
  right: auto;
  left: 100%;
}

.member-entry {
  border: 1px solid #ebebeb;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.02);
  transition: all 300ms ease-in-out;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}

.member-entry:after, .member-entry:before {
  content: " ";
  display: table;
}

.member-entry:after {
  clear: both;
}

.member-entry:hover {
  background: rgba(235, 235, 235, 0.3);
  box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.06);
}

.member-entry .member-details, .member-entry .member-img {
  float: left;
}

.member-entry .member-img {
  position: relative;
  display: block;
  width: 10%;
}

.member-entry .member-img img {
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
}

.member-entry .member-img i {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -12.5px;
  margin-left: -12.5px;
  color: #FFF;
  font-size: 25px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: scale(0.5);
  transition: all 300ms ease-in-out;
}

.member-entry .member-img:hover i {
  transform: scale(1);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.member-entry .member-details {
  width: 89.9%;
}

.member-entry .member-details h4 {
  font-size: 18px;
  margin-left: 20px;
}

.member-entry .member-details h4 a {
  transition: all 300ms ease-in-out;
}

.member-entry .member-details .info-list {
  margin-left: 5px;
}

.member-entry .member-details .info-list > div {
  margin-top: 5px;
  font-size: 13px;
}

.member-entry .member-details .info-list > div a, .member-entry .member-details .info-list > div i {
  transition: all 300ms ease-in-out;
}

.member-entry .member-details .info-list > div:hover i {
  color: #4f5259;
}

@media screen and (max-width: 768px) {
  .member-entry .member-img {
    width: 18%;
  }

  .member-entry .member-details {
    width: 81.9%;
  }

  .member-entry .member-details h4 {
    margin-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .member-entry .member-img {
    width: 100%;
    float: none;
    text-align: center;
    position: relative;
    background: #f8f8f8;
    margin-bottom: 15px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
  }

  .member-entry .member-img img {
    width: auto;
    display: inline-block;
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
  }

  .member-entry .member-details {
    width: 100%;
    float: none;
  }

  .member-entry .member-details .info-list, .member-entry .member-details h4 {
    margin-left: 0;
  }

  .member-entry .member-details .info-list > div, .member-entry .member-details h4 > div {
    padding: 0;
  }

  .member-entry .member-details .info-list > div {
    margin-top: 10px;
  }
}

.comments-env .comment-filters {
  margin: 15px 0 30px;
}

.comments-env .comment-filters > a {
  position: relative;
  display: inline-block;
  color: #93979e;
  margin: 0 15px;
  font-size: 13px;
  font-weight: 400;
}

.comments-env .comment-filters > a.current {
  font-weight: 700;
}

.comments-env .comment-filters > a:after {
  content: '';
  display: block;
  position: absolute;
  right: -18px;
  width: 1px;
  height: 12px;
  background: #ebebeb;
  top: 3px;
}

.comments-env .comment-filters > a:first-child {
  margin-left: 0;
}

.comments-env .comment-filters > a:last-child {
  margin-right: 0;
}

.comments-env .comment-filters > a:last-child:after {
  display: none;
}

.comments-env .filtering .selectboxit-btn {
  height: 38px;
}

.comments-env .filtering .selectboxit-btn span {
  height: 38px;
  line-height: 38px;
}

.comments-env .filtering .search-form-full .form-control {
  margin-top: 0;
}

.comments-env .filtering .search-form-contaner {
  max-width: 300px;
  width: 32%;
  float: right;
}

.comments-env .filtering .search-form-contaner + .pagination-container {
  margin-right: 20px;
}

.comments-env .filtering .pagination-container {
  float: right;
}

.comments-env .filtering .pagination-container .pagination {
  margin: 0;
}

.comments-env .filtering .pagination-container .pagination a {
  position: relative;
  padding-top: 9px;
  padding-bottom: 10px;
  margin-top: -2px;
}

.comments-env .filtering .pagination-container + .search-form-contaner {
  margin-right: 20px;
}

.comments-env .panel-title h4 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.comments-list > li {
  border-bottom: 1px solid #ebebeb;
  padding: 15px;
}

.comments-list > li:after, .comments-list > li:before {
  content: " ";
  display: table;
}

.comments-list > li:after {
  clear: both;
}

.comments-list > li:nth-child(even) {
  background: #f5f5f6;
}

.comments-list > li .comment-checkbox {
  float: left;
  width: 3%;
  padding-top: 3px;
}

.comments-list > li .comment-details {
  float: right;
  width: 96.9%;
}

.comments-list > li .comment-details a {
  transition: all 300ms ease-in-out;
}

.comments-list > li .comment-details .comment-head {
  font-size: 13px;
}

.comments-list > li .comment-details .comment-head a {
  font-weight: 700;
}

.comments-list > li .comment-details .comment-text {
  margin-top: 15px;
}

.comments-list > li .comment-details .comment-footer {
  border-top: 1px solid #ebebeb;
  margin-top: 15px;
  padding-top: 15px;
}

.comments-list > li .comment-details .comment-footer:after, .comments-list > li .comment-details .comment-footer:before {
  content: " ";
  display: table;
}

.comments-list > li .comment-details .comment-footer:after {
  clear: both;
}

.comments-list > li .comment-details .comment-footer .comment-time {
  float: right;
  color: #80858e;
}

.comments-list > li .comment-details .comment-footer .action-links a {
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
}

.comments-list > li .comment-details .comment-footer .action-links .approve {
  color: #00a651;
}

.comments-list > li .comment-details .comment-footer .action-links .delete {
  color: #cc2424;
}

.comments-list > li:last-child {
  border-bottom: 0;
}

@media screen and (max-width: 768px) {
  .comments-list > li .comment-checkbox {
    width: 5%;
  }

  .comments-list > li .comment-details {
    width: 94.9%;
  }

  .comments-env .filtering .search-form-contaner {
    float: left;
  }

  .search-and-pagination {
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .comments-env .search-and-pagination .pagination-container, .comments-env .search-and-pagination .search-form-contaner {
    float: none !important;
    text-align: left;
  }

  .comments-env .search-and-pagination .search-form-contaner {
    margin-right: 0;
    width: 100%;
    max-width: 1000px;
  }

  .comments-env .search-and-pagination .search-form-contaner .search-form-full {
    margin-top: 10px;
  }

  .comments-list > li .comment-checkbox {
    width: 8%;
  }

  .comments-list > li .comment-details {
    width: 91.9%;
  }

  .comments-list > li .comment-details .comment-footer .action-links {
    float: none;
    width: 100%;
  }

  .comments-list > li .comment-details .comment-footer .action-links a {
    display: block;
  }
}

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-empty, .dd-item, .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-content, .dd-handle {
  display: block;
  margin: 0;
  padding: 5px 10px;
  background: #fafafa;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  transition: all 300ms ease-in-out;
}

.dd-content:hover, dd-content {
  background: #fff;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 10px 5px 0;
  padding: 0;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: visible;
  border: 0;
  background: 0 0;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: 700;
  outline: 0;
}

.dd-item > button:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: -5px;
  bottom: -6px;
  width: 1px;
  background: #ebebeb;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action=collapse]:before {
  content: '-';
}

.dd-empty, .dd-placeholder {
  margin: 0;
  padding: 0;
  min-height: 30px;
  background: rgba(245, 245, 246, 0.3);
  border: 1px dotted #ebebeb;
  box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-content, .dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-content, .dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.nested-list.with-margins .dd-item .dd-content, .nested-list.with-margins .dd-item .dd-handle {
  margin: 5px 0;
}

.nested-list.custom-drag-button .dd-handle {
  position: absolute !important;
  margin: 0 !important;
  width: 15px;
  height: 32px;
  padding-left: 5px;
  padding-right: 4px;
  cursor: pointer;
  padding-top: 7px;
  box-shadow: none;
}

.nested-list.custom-drag-button .dd-handle span {
  display: block;
  line-height: 3px;
  color: rgba(115, 120, 129, 0.7);
}

.nested-list.custom-drag-button .dd-content {
  padding-left: 25px;
}

.nested-list.custom-drag-button button + .dd-handle {
  left: 24px;
}

.nested-list.custom-drag-button button + .dd-handle + .dd-content {
  padding-left: 50px;
}

.nested-list.custom-drag-button.drag-button-on-hover .dd-item > .dd-handle {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.nested-list.custom-drag-button.drag-button-on-hover .dd-item:hover > .dd-handle {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.custom-handler .dd-item .dd-handle {
  position: absolute !important;
  margin: 0 !important;
  width: 15px;
  height: 32px;
  padding-left: 5px;
  padding-right: 4px;
  cursor: pointer;
  padding-top: 7px;
  box-shadow: none;
}

.custom-handler .dd-item .dd-handle span {
  display: block;
  line-height: 3px;
  color: rgba(115, 120, 129, 0.7);
}

.custom-handler .dd-item .dd-content {
  padding-left: 25px;
}

.custom-handler .dd-item button + .dd-handle {
  left: 24px;
}

.custom-handler .dd-item button + .dd-handle + .dd-content {
  padding-left: 50px;
}
